export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  datetimeFormats: DateTimeFormat as any,
  messages: Messages,
}));

const Messages = {
  en: {
    createdAt: "Created at",
    recordedAt: "Recorded at",
    number: "Number",
    createTeam: "Create team",
    recentTasks: "Recent tasks",
    deleteTeam: "Delete team",
    deleteMember: "Delete member",
    deleteTask: "Delete task",
    welcome: "Welcome",
    continue: "Continue",
    settings: "Settings",
    tracking: "Tracking",
    teams: "Teams",
    logout: "Logout",
    login: "Login",
    invalidEmail: "Invalid email",
    home: "Home",
    team: "Team",
    tasks: "Tasks",
    languages: "Languages",
    userName: "User name",
    password: "Password",
    receiverName: "Full name",
    start: "Start",
    accept: "Accept",
    decline: "Decline",
    successful: "Réussie",
    failed: "Échoué",
    assigned: "Affecté",
    unAssigned: "Non affecté",
    cancel: "Cancel",
    images: "Images",
    notes: "Notes",
    signatures: "Signatures",
    arrived: "Arrived",
    addPictures: "Add pictures",
    addPicture: "Add picture",
    mandatoryFieldErrorMsg: "This field is required",
    areYouSure: "Are you sure?",
    doYouWantToExitAnApp: "Do you want to exit an App",
    no: "No",
    yes: "Yes",
    enterYourEmailAddress: "Enter your email address",
    enterYourPassword: "Enter your password",
    invalidUserNameAndPassword: "Invalid user name and password",
    notification: "Notification",
    termsServices: "Terms & Services",
    off: "Off",
    on: "On",
    map: "Map",
    clearAll: "Clear All",
    areYouSureYouWantToDeclineAllNotifications:
      "Are you sure you want to decline all notifications?",
    thereIsNoNotificationYet: "There is no notification yet",
    allowAppToAcessYourContactsList: "Allow app to acess your contacts list?",
    youNeedToAllowAllPermissionsInOrderToUseThisApplication:
      "You need to allow all permissions in order to use this application, if you don't allow your application will be closed",
    allow: "Allow",
    dontAllow: "Don't allow",
    thankYouForYourHardWork:
      "Thank you for your hard work, you arrived the order successfully",
    congratulations: "Congratulations",
    clear: "Clear",
    signature: "Signature",
    thereIsNoTaskYet: "There is no task yet",
    all: "All",
    accepted: "Accepted",
    declined: "Declined",
    started: "Started",
    pickUpAddress: "Pick Up Address",
    goToTheTask: "Go to the task",
    view: "View",
    description: "Description",
    current: "Current",
    sorry: "Sorry",
    youCantStart:
      "You can't start a new order while having one, you should finish the current order first",
    offline: "Offline",
    online: "Online",
    busy: "busy",
    areYouSureTask: "Are you sure you want to decline this task?",
    enterYourReason: "Enter your reason",
    confirm: "Confirm",
    failedTaskReason:
      "Could you please provide more details on why this task was marked as failed?",
    aboutUs: "About us",
    contactUs: "Contact us",
    billing: "Billing",
    createTask: "Create task",
    passwordMustBeAtLeast8characters: "Password must be at least 8 characters",
    credentialsNotMuch: "These credentials do not match our records.",
    loadMoreTasks: "Load more tasks",
    noMoreTasks: "No more tasks",
    createANewAccount: "Create a new account",
    signInToYourAccount: "Sign in to your account",
    or: "Or",
    register: "Register",
    editFields: "Edit fields",
    addField: "add field",
    label: "Etiquette",
    type: "Type",
    required: "required",
    delete: "Delete",
    EditTaskFields: "Edit task field",
    save: "Save",
    saved: "Saved",
    edit: "Edit",
    creationDate: "Creation date",
    status: "Status",
    receiverAddress: "Receiver address",
    receiverLongtitude: "Receiver longtitude",
    receiverLatitude: "Receiver latitude",
    phoneNumber: "Phone number",
    areYouSureDeleteTask:
      "Are you sure do you want to delete the task {taskNumber}?",
    areYouSureDeleteTeam:
      "Are you sure do you want to delete the team {teamName}?",
    areYouSureDeleteAgent: "Are you sure do you want to delete the agent?",
    areYouSureDeleteTeamMember:
      "Are you sure do you want to remove the member {memberName} from the team?",
    general: "General",
    task: "Task",
    newTask: "New task",
    agents: "Agents",
    newTeam: "New team",
    newUser: "New user",
    hasNetwork: "Has network",
    receiverPhone: "Receiver phone",
    process_date: "Process date",
    hasMockLocation: "Has mock location",
    batteryLevel: "Battery level",
    lastActive: "last active",
    name: "Name",
    filterTasks: "Filter tasks",
    loadMore: "Load more",
    in_progress: "In progress",
    editTask: "Edit task",
    pending: "Pending",
    canceled: "Canceled",
    email: "Email",
    newAgent: "New agent",
    actions: "actions",
    refresh: "Refresh",
    starter: "Starter",
    pro: "Pro",
    advanced: "Advanced",
    choosePlan: "Choose plan",
    deleted: "Deleted",
    cancelled: "Cancelled",
    manager: "Manager",
    agent: "Agent",
    profile: "Profile",
    teamMembers: "Team members",
    free: "Free",
    premium: "Premium",
    standard: "Standard",
    plan: "Plan",
    role: "Role",
    activateYourAccount: "Your account is not activated!",
    resendActivationLink: "Resend activation link",
    activationMsg:
      "We've sent an email to your registered address. Please click the verification link to activate your account. You won't be able to use our solution until your account is activated.",
  },
  fr: {
    createdAt: "Créé à",
    recordedAt: "Enregistré à",
    number: "Nombre",
    createTeam: "Creation équipe",
    recentTasks: "Tâches récentes",
    deleteTeam: "Supprimer l'équipe",
    deleteMember: "Supprimer le membre",
    deleteTask: "Supprimer la tâche",
    continue: "Poursuivre",
    welcome: "Bienvenue",
    helloWorld: "Hola Mundo!",
    title: "Titre",
    settings: "Paramètres",
    tracking: "Localisation",
    teams: "Equipes",
    team: "Equipe",
    logout: "Deconnexion",
    login: "Connexion",
    home: "Accueil",
    tasks: "Tâches",
    languages: "Languages",
    userName: "Nom d'utilisateur",
    password: "Mot de passe",
    start: "Démarrer",
    accept: "Accepter",
    decline: "Refuser",
    successful: "Successfull",
    failed: "Failed",
    assigned: "Assigned",
    unAssigned: "Unassigned",
    cancel: "Annuler",
    images: "Photos",
    notes: "Remarques",
    signatures: "Signatures",
    arrived: "Arrivée",
    addPictures: "Ajouter des photos",
    addPicture: "Ajouter une photo",
    mandatoryFieldErrorMsg: "Ce champ est requis",
    areYouSure: "Es-tu sûr?",
    doYouWantToExitAnApp: "Voulez-vous quitter une application",
    no: "Non",
    yes: "Oui",
    enterYourEmailAddress: "Entrez votre adresse email",
    enterYourPassword: "Tapez votre mot de passe",
    invalidUserNameAndPassword: "Nom d'utilisateur et mot de passe invalides",
    notification: "Notification",
    termsServices: "Conditions et services",
    off: "Désactivé",
    on: "Sur",
    map: "Carte",
    clearAll: "Tout effacer",
    areYouSureYouWantToDeclineAllNotifications:
      "Êtes-vous sûr de vouloir refuser toutes les notifications?",
    thereIsNoNotificationYet: "Il n'y a pas encore de notification",
    allowAppToAcessYourContactsList:
      "Autoriser l'application à accéder à votre liste de contacts?",
    youNeedToAllowAllPermissionsInOrderToUseThisApplication:
      "Vous devez autoriser toutes les autorisations pour utiliser cette application, si vous ne l'autorisez pas, votre application sera fermée",
    allow: "Permettre",
    dontAllow: "Ne permettez pas",
    thankYouForYourHardWork:
      "Merci pour votre travail acharné, vous avez reçu la commande avec succès",
    congratulations: "Toutes nos félicitations",
    clear: "Clair",
    signature: "Signature",
    thereIsNoTaskYet: "Il n'y a pas encore de tâche",
    all: "Tout",
    accepted: "Accepté",
    declined: "Diminué",
    started: "Commencé",
    pickUpAddress: "Adresse de prise en charge",
    goToTheTask: "Aller à la tâche",
    view: "Voir",
    description: "Description",
    current: "Actuel",
    sorry: "Désolé",
    youCantStart:
      "Vous ne pouvez pas démarrer une nouvelle commande tout en en ayant une, vous devez d'abord terminer la commande en cours",
    offline: "Hors ligne",
    online: "En ligne",
    busy: "occupé",
    areYouSureTask: "Êtes-vous sûr de vouloir refuser cette tâche ?",
    enterYourReason: "Entrez votre raison",
    confirm: "Confirmer",
    failedTaskReason:
      "Pourriez-vous s'il vous plaît fournir plus de détails sur la raison pour laquelle cette tâche a été marquée comme ayant échoué ?",
    aboutUs: "À propos de nous",
    contactUs: "Contactez-nous",
    billing: "Facturation",
    createTask: "Créer une tâche",
    invalidEmail: "Email invalide",
    passwordMustBeAtLeast8characters:
      "Mot de passe doit être d'au moins 8 caractères",
    credentialsNotMuch:
      "Ces informations d'identification ne correspondent pas à nos dossiers.",
    loadMoreTasks: "Charger plus de tâches",
    noMoreTasks: "Plus de tâches",
    createANewAccount: "Créer un nouveau compte",
    signInToYourAccount: "Connectez-vous à votre compte",
    or: "Ou",
    register: "Registrer",
    editFields: "Modifier les champs",
    addField: "ajouter un champ",
    label: "Etiquette",
    type: "Type",
    required: "obligatoire",
    delete: "Supprimer",
    EditTaskFields: "Modifier les champs de tâches",
    save: "Enregister",
    saved: "enregistrée",
    edit: "Modifier",
    creationDate: "Date de création",
    status: "Statut",
    receiverAddress: "Adresse du destinataire",
    receiverLongtitude: "Longtitude du destinataire",
    receiverLatitude: "Latitude du destinataire",
    phoneNumber: "Numéro de téléphone",
    areYouSureDeleteTask:
      "Êtes-vous sûr de vouloir supprimer la tâche {taskNumber}?",
    areYouSureDeleteTeam: "Êtes-vous sûr de vouloir supprimer l'équipe?",
    areYouSureDeleteAgent: "Êtes-vous sûr de vouloir supprimer l'agent?",
    areYouSureDeleteTeamMember:
      "Êtes-vous sûr de vouloir supprimer le membre {memberName} de l'équipe?",
    general: "Generale",
    newTask: "Nouvelle tâche",
    agents: "Agents",
    newTeam: "Nouvelle équipe",
    newUser: "nouvel utilisateur",
    hasNetwork: "A un réseau",
    receiverName: "Nom complet",
    receiverPhone: "Téléphone du destinataire",
    process_date: "Date de traitement",
    hasMockLocation: "A une fausse localisation",
    batteryLevel: "Niveau de batterie",
    lastActive: "Dernière activité",
    name: "Nom",
    filterTasks: "Filtrer les tâches",
    loadMore: "Charger plus",
    in_progress: "En cours",
    editTask: "Modifier la tâche",
    pending: "En attente",
    canceled: "Annulé",
    email: "Email",
    newAgent: "Nouvel agent",
    actions: "actions",
    refresh: "Actualiser",
    starter: "Démarreur",
    pro: "Pro",
    Advanced: "Avancé",
    choosePlan: "Choisissez ce plan",
    deleted: "Supprimé",
    cancelled: "Annulé",
    manager: "Gérant",
    agent: "Agent",
    profile: "Profil",
    teamMembers: "Membres",
    free: "Free",
    premium: "Premium",
    standard: "Standard",
    plan: "Plan",
    role: "Role",
  },
};

const DateTimeFormat = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
};
